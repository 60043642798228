import { ReactNode } from 'react';
import { useAuth } from '@core/auth';
import { useRouter } from 'next/navigation';
import { ROUTES } from '@core/config';
import LoadingScreen from '@core/ui/components/loading-screen';
import { shouldShowOnboarding } from '@features/onboarding/utils';
import { useCurrentWorkspace } from '@features/workspaces/provider';

interface Props {
  children: ReactNode;
  skipOnboardingCheck?: boolean;
}

export default function PrivateGuard({ children, skipOnboardingCheck }: Props) {
  const { initialized, user } = useAuth();
  const { initialized: workspaceInitialized } = useCurrentWorkspace();
  const { replace } = useRouter();

  if (!initialized || !workspaceInitialized) {
    return <LoadingScreen />;
  }

  if (!user) {
    replace(ROUTES.signIn);
    return null;
  }

  if (!skipOnboardingCheck) {
    if (shouldShowOnboarding(user)) {
      replace(ROUTES.onboarding);
      return null;
    }
  }

  return <> {children} </>;
}
