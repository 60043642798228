import { useEffect } from 'react';
import { useAuth } from '@core/auth';
import { useRouter } from 'next/navigation';
import { ROUTES } from '@core/config';
import LoadingScreen from '@core/ui/components/loading-screen';

export default function WorkspaceGuard() {
  const { replace } = useRouter();
  const { user } = useAuth();

  const workspace = user.workspaces[0]?.workspace;

  useEffect(() => {
    if (workspace) {
      replace(ROUTES.workspace.rootId(workspace.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoadingScreen />;
}
